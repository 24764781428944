<template>
  <Container>
    <Header>
      {{ user.name ? user.name : "no username" }}
      <template #right>
        <BadgeBtn @click="openUser()">
          <BIconPencilSquare />
        </BadgeBtn>
      </template>
    </Header>

    <DetailElement sub="Details : ">
      {{ user.name ? user.name : "name not set" }} <br />
      {{ user.roleText ? user.roleText : "role not set" }} <br />
      {{ firebaseUser.email }}
    </DetailElement>
    <DetailElement sub="id : "> {{ user.id }} <br /> </DetailElement>
    <DetailElement sub="email verified : ">
      {{ firebaseUser.emailVerified ? "yes" : "no" }} <br />
    </DetailElement>
  </Container>
</template>

<script>
import comp from "@/components/base"
import { mapActions } from "vuex"
import icons from "../assets/icons"
import { getUser } from "../services/auth"
import { useStore } from "@/store/store"

export default {
  name: "User",
  components: {
    ...comp,
    ...icons,
  },
  setup() {
    return {
      // user: computed(() => useStore("users").getters.getUser),
    }
  },
  computed: {
    user() {
      return useStore().getters.PopUser
    },
    firebaseUser() {
      return getUser()
    },
  },
  methods: {
    ...mapActions(["openUser"]),
  },
}
</script>
